import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { CloseOutlined, Send as SendIcon } from "@mui/icons-material";
import axios from "axios";

const DeviceForm = ({ handleClose, getUsers, editUser }) => {
  const [selectButton, setSelectButton] = useState(
    editUser ? editUser.selectButton : ""
  );
  const [name, setName] = useState(editUser ? editUser.name : "");
  const [userName, setUserName] = useState(editUser ? editUser.userName : "");
  const [password, setPassword] = useState(editUser ? editUser.password : "");
  const [id, setId] = useState(editUser ? editUser._id : null);

  // useEffect(() => {
  //   if (editUser) {
  //     setPassword("");
  //   }
  // }, [editUser]);

  const handleChange = (event) => {
    setSelectButton(event.target.value);
  };

  const onSubmit = async () => {
    try {
      if (
        name === "" ||
        userName === "" ||
        (!editUser && password === "") ||
        selectButton === ""
      ) {
        alert("Please fill all the information");
        return;
      }

      const deviceData = {
        name,
        userName,
        password,
        selectButton,
      };

      // 192.168.242.139 local IP
      // 3.39.192.245 server IP

      const apiUrl = editUser
        ? `https://server.buttonlinker.xyz/api/user/${id}`
        : "https://server.buttonlinker.xyz/api/user";

      const method = editUser ? "put" : "post";

      const response = await axios({
        method,
        url: apiUrl,
        data: deviceData,
      });

      if (response.status === 200 || response.status === 201) {
        getUsers();
        handleClose();
      } else {
        console.error(`Unexpected response status: ${response.status}`);
        // Handle other unexpected responses here
        alert("Unexpected error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      // Handle the error appropriately, for example, show an error message to the user
      alert("An error occurred while submitting data. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "Center",
        }}
      >
        <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
          {editUser ? "Edit Device" : "Add Device"}
        </Typography>
        <CloseOutlined
          sx={{
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Box>
      <TextField
        onChange={(e) => setName(e.target.value)}
        value={name}
        id="outlined-basic"
        label="Name"
        variant="outlined"
        sx={{
          width: "20rem",
        }}
      />
      <TextField
        onChange={(e) => setUserName(e.target.value)}
        value={userName}
        id="outlined-basic"
        label="Username"
        variant="outlined"
        sx={{
          width: "20rem",
        }}
      />
      <TextField
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        id="outlined-basic"
        label="Password"
        variant="outlined"
        type="password"
        sx={{
          width: "20rem",
        }}
      />
      <FormControl
        sx={{
          width: "20rem",
        }}
      >
        <InputLabel id="demo-simple-select-label">Select Button</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectButton}
          label="Select Button"
          onChange={handleChange}
        >
          <MenuItem value={"up"}>UP</MenuItem>
          <MenuItem value={"down"}>DOWN</MenuItem>
          <MenuItem value={"shake"}>SHAKE</MenuItem>
        </Select>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            background: "#0174BE",
          }}
          variant="contained"
          startIcon={<SendIcon />}
          onClick={onSubmit}
        >
          {editUser ? "Update" : "Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default DeviceForm;
