import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "name", headerName: "Name", width: 170, editable: true },
  { field: "userName", headerName: "User Name", width: 170, editable: true },
  {
    field: "button",
    headerName: "Selected Button",
    width: 170,
    editable: true,
  },
];

const OnlineUsers = ({ users }) => {
  const rows = users?.map((user, index) => {
    return {
      id: index + 1,
      name: user?.name,
      userName: user?.userName,
      button: user?.selectButton,
    };
  });

  return (
    <Box sx={{ width: "90%", mx: "auto", mt: 4 }}>
      <Box
        sx={{
          height: 530,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "@media(max-width: 780px)": {
            width: "95%",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={8}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default OnlineUsers;
