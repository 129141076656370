import React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import ComputerIcon from "@mui/icons-material/Computer";
import { ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import {
  MobileOffSharp,
  OnlinePrediction,
  Settings,
} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

const Header = ({ setIsLogin }) => {
  const logout = () => {
    localStorage.removeItem("masterLogin");
    setIsLogin(false);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to={"/"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ComputerIcon />
              </ListItemIcon>
              <ListItemText primary={"Devices"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/mobile"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <PhoneAndroidIcon />
              </ListItemIcon>
              <ListItemText primary={"Mobile"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/online-users"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <OnlinePrediction />
              </ListItemIcon>
              <ListItemText primary={"Online Users"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/settings"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <ListItem disablePadding onClick={logout}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      >
        <MenuIcon
          fontSize="large"
          sx={{
            cursor: "pointer",
            color: "#0174BE",
          }}
          onClick={toggleDrawer("right", true)}
        />
      </Box>
      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </Box>
  );
};

export default Header;
