import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import logo from "../Logo.png";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = ({ setIsLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const submit = async () => {
    const response = await axios.get(
      "https://server.buttonlinker.xyz/api/master"
    );
    const userNameDb = response?.data[response?.data?.length - 1]?.userName;
    const passwordDb = response?.data[response?.data?.length - 1]?.password;
    if (userNameDb == username && passwordDb == password) {
      setIsLogin(true);
      localStorage.setItem("masterLogin", true);
      navigate("/");
    } else {
      alert("Please enter the correct username and password");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          padding: "5rem 3rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img src={logo} alt="" width={"80"} style={{ marginBottom: "2rem" }} />
        <TextField
          className="input"
          id="outlined-basic"
          label="Username"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          className="input"
          label="Password"
          variant="outlined"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button color="error" variant="contained" onClick={submit}>
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
