import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DeviceForm from "../components/DeviceForm";
import axios from "axios";
import { Delete, Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 3,
};

// 192.168.242.139 local IP
// 3.39.192.245 server IP
const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null); // Track the user to edit
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditUser(null); // Reset editUser when closing the modal
  };
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const response = await axios.get(
        "https://server.buttonlinker.xyz/api/users"
      );
      setUsers(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (userId) => {
    try {
      axios
        .delete(`https://server.buttonlinker.xyz/api/user/${userId}`)
        .then(() => {
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== userId)
          );
          getUsers();
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []); // Add an empty dependency array to run the effect only once

  const handleEdit = (userId) => {
    const userToEdit = users.find((user) => user._id === userId);
    setEditUser(userToEdit);
    handleOpen();
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      editable: true,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 170,
      editable: true,
    },
    {
      field: "button",
      headerName: "Button",
      width: 150,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            color="primary"
            onClick={() => handleEdit(params.row.data._id)}
          >
            <Edit />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => handleDelete(params.row.data._id)}
          >
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = users?.map((user, idx) => {
    return {
      id: idx + 1,
      name: user?.name,
      userName: user?.userName,
      button: user?.selectButton,
      data: user,
    };
  });

  return (
    <Box
      sx={{
        width: "90%",
        mx: "auto",
        mt: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
        }}
      >
        <Button
          sx={{
            background: "#0174BE",
          }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Add
        </Button>
      </Box>
      <Box
        sx={{
          height: 530,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "@media(max-width: 780px)": {
            width: "95%",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={8}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <DeviceForm
            editUser={editUser}
            getUsers={getUsers}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default Home;
