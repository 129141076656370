import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import logo from "../Logo.png";
import Cookies from "js-cookie";
import axios from "axios";

const Settings = ({ setIsLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const logout = () => {
    localStorage.removeItem("masterLogin");
    setIsLogin(false);
  };

  const submit = async () => {
    // Cookies.set("username", username);
    // Cookies.set("password", password);

    const apiUrl = "https://server.buttonlinker.xyz/api/master";

    const response = await axios({
      method: "POST",
      url: apiUrl,
      data: {
        userName: username,
        password: password,
      },
    });
    logout();
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          padding: "5rem 3rem",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img src={logo} alt="" width={"80"} />
        <h3>Change Master App Password</h3>
        <TextField
          className="input"
          id="outlined-basic"
          label="Username"
          variant="outlined"
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="outlined-basic"
          className="input"
          label="Password"
          variant="outlined"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button color="error" variant="contained" onClick={submit}>
          Change Password
        </Button>
      </Box>
    </Box>
  );
};

export default Settings;
