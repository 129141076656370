import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Edit, CloseOutlined, Send as SendIcon } from "@mui/icons-material";

const Mobile = ({ logoutFromMobile }) => {
  const [mobileUser, setMobileUser] = useState(null);
  const [editUser, setEditUser] = useState(false);
  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 3,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = (data) => {
    setOpen(true);
    setEditUser(true);
    setName(data?.name);
    setUserName(data?.userName);
    setPassword(data?.password);
  };
  const handleClose = () => {
    setOpen(false);
    setEditUser(false);
    setName("");
    setUserName("");
    setPassword(""); // Reset editUser when closing the modal
  };

  const onSubmit = async () => {
    try {
      const data = { name, userName, password };
      if (editUser) {
        await axios.put("https://server.buttonlinker.xyz/api/mobileUser", data);
      } else {
        await axios.post(
          "https://server.buttonlinker.xyz/api/mobileUser",
          data
        );
      }
      handleClose();
      logoutFromMobile();
      fetchMobileUser();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const fetchMobileUser = async () => {
    try {
      const response = await axios.get(
        "https://server.buttonlinker.xyz/api/mobileUser"
      );
      setMobileUser(response.data);
    } catch (error) {
      console.error("Error fetching mobile user:", error);
    }
  };

  useEffect(() => {
    fetchMobileUser();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "name", headerName: "Name", width: 170, editable: true },
    { field: "userName", headerName: "User Name", width: 170, editable: true },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton color="primary" onClick={() => handleOpen(params.row)}>
            <Edit />
          </IconButton>
        </>
      ),
    },
  ];

  const rows = mobileUser
    ? [
        {
          id: 1,
          name: mobileUser?.name,
          userName: mobileUser?.userName,
          password: mobileUser?.password,
        },
      ]
    : [];

  return (
    <Box sx={{ width: "90%", mx: "auto", mt: 4 }}>
      <Box
        sx={{
          height: 530,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          "@media(max-width: 780px)": {
            width: "95%",
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={8}
          checkboxSelection={false}
          disableRowSelectionOnClick
        />
      </Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "Center",
            }}
          >
            <Typography fontSize={"1.4rem"} fontWeight={"bold"}>
              {editUser ? "Edit Device" : "Add Device"}
            </Typography>
            <CloseOutlined
              sx={{
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              my: "1rem",
            }}
          >
            <TextField
              onChange={(e) => setName(e.target.value)}
              value={name}
              id="outlined-basic"
              label="Name"
              variant="outlined"
              sx={{
                width: "20rem",
              }}
            />
            <TextField
              onChange={(e) => setUserName(e.target.value)}
              value={userName}
              id="outlined-basic"
              label="Username"
              variant="outlined"
              sx={{
                width: "20rem",
              }}
            />
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              sx={{
                width: "20rem",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                background: "#0174BE",
              }}
              variant="contained"
              startIcon={<SendIcon />}
              onClick={onSubmit}
            >
              {editUser ? "Update" : "Submit"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Mobile;
