// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  // http://server.buttonlinker.xyz/
  useEffect(() => {
    const newSocket = io("https://server.buttonlinker.xyz");
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  // Additional methods
  const emitMessage = (eventName, data) => {
    if (socket) {
      socket.emit(eventName, data);
    }
  };

  const onMessage = (eventName, callback) => {
    if (socket) {
      socket.on(eventName, callback);
    }
  };

  const disconnectSocket = () => {
    if (socket) {
      socket.disconnect();
    }
  };

  return (
    <SocketContext.Provider
      value={{ socket, emitMessage, onMessage, disconnectSocket }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return context;
};

export { SocketProvider, useSocket };
