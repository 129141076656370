import { Box } from "@mui/material";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import Home from "./Pages/Home";
import Header from "./components/Header";
import Mobile from "./Pages/Mobile";
import { useSocket } from "./socket/SocketProvider";
import OnlineUsers from "./Pages/OnlineUsers";
import Login from "./components/Login";
import Settings from "./Pages/Settings";

function App() {
  const { socket, emitMessage, onMessage, disconnectSocket } = useSocket();

  const [loggedInUsers, setLoggedInUsers] = useState([]);
  const [isLogin, setIsLogin] = useState(
    localStorage.getItem("masterLogin") || false
  );
  const logoutFromMobile = () => {
    emitMessage("logoutOnMobile", "logout");
  };

  useEffect(() => {
    onMessage("loggedInUsers", (data) => {
      setLoggedInUsers(data);
    });

    return () => {
      // Disconnect the socket when the component unmounts
      disconnectSocket();
    };
  }, [onMessage, emitMessage, disconnectSocket]);

  return (
    <Box>
      <BrowserRouter>
        {!isLogin ? (
          <Login setIsLogin={setIsLogin} />
        ) : (
          <Box>
            <Header setIsLogin={setIsLogin} />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route
                exact
                path="/mobile"
                element={<Mobile logoutFromMobile={logoutFromMobile} />}
              />
              <Route
                exact
                path="/online-users"
                element={<OnlineUsers users={loggedInUsers} />}
              />
              <Route
                exact
                path="/settings"
                element={<Settings setIsLogin={setIsLogin} />}
              />
            </Routes>
          </Box>
        )}
      </BrowserRouter>
    </Box>
  );
}

export default App;
